import React from 'react';

const ConfirmationPage = () => {
  return (
    <div className="end-step-container">
      <h2>🎉 Done.</h2>
      <p>Your information has been successfully submitted.</p>
      <p>We will get in touch with you soon.</p>
   <a href="/">Learn More About BonusClub</a>
    </div>
  );
};

export default ConfirmationPage;
