import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage1 from './Landingpage/LandingPage1';
import SignIn from './Admin/SignIn';
import ProtectedRoute from './Admin/ProtectedRoute';
import FreelanceOnboarding from './FreelanceQuestions/FreelanceOnboarding';
import TeamDashboard from './Admin/Teamdashboard';
import PrivacyPolicy from './Landingpage/PrivacyPolicy';
import TermsAndConditions from './Landingpage/TermsAndConditions';
import Imprint from './Landingpage/Imprint';
import ScrollToTop from './Landingpage/ScrollToTop';
import LandingPageBnb1 from './Landingpage/LandingPageBnb1';
import LandingPageBnb2 from './Landingpage/LandingPageBnb2';
import LandingPageBnb3 from './Landingpage/LandingPageBnb3';
import LandingPageBnb4 from './Landingpage/LandingPageBnb4';
import LandingPageBnb5 from './Landingpage/LandingPageBnb1CTA';
import LandingPageBnb1CTA from './Landingpage/LandingPageBnb1CTA';
import LandingPageBnb2CTA from './Landingpage/LandingPageBnb2CTA';
import LandingPageBnb3CTA from './Landingpage/LandingPageBnb3CTA';
import LandingPageBnb4CTA from './Landingpage/LandingPageBnb4CTA';
import BookingPage1 from './Landingpage/BookingPage1';
import PasswordProtection from './Landingpage/PasswordProtection';

// Firebase Auth Kontext erstellen
export const AuthContext = createContext({
  isAuthenticated: false,
  shouldUseFirebase: false
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [shouldUseFirebase, setShouldUseFirebase] = useState(false);
  
  // Beim Laden der App prüfen, ob der Benutzer bereits authentifiziert ist
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
      // Firebase-Zugriff erst nach kurzer Verzögerung aktivieren
      setTimeout(() => {
        setShouldUseFirebase(true);
      }, 1000);
    }
  }, []);
  
  // Erfolgreiches Login behandeln
  const handleAuthentication = () => {
    setIsAuthenticated(true);
    // Firebase-Zugriff erst nach erfolgreicher Authentifizierung aktivieren
    setTimeout(() => {
      setShouldUseFirebase(true);
    }, 1000);
  };

  // Wenn nicht authentifiziert, Passwortschutz anzeigen
  if (!isAuthenticated) {
    return <PasswordProtection onAuthenticated={handleAuthentication} />;
  }

  // Ansonsten die normale App rendern
  return (
    <AuthContext.Provider value={{ isAuthenticated, shouldUseFirebase }}>
      <Router>
        <div className="App">
          <ScrollToTop /> {/* Füge ScrollToTop hier ein */}
          <Routes>
            <Route path="/" element={<LandingPage1 />} />
          
            <Route path="/_1airbnb" element={<LandingPageBnb1 />} />
            <Route path="/_2airbnb" element={<LandingPageBnb2 />} />
            <Route path="/_3airbnb" element={<LandingPageBnb3 />} />
            <Route path="/_4airbnb" element={<LandingPageBnb4 />} />
            <Route path="/_1airbnbs" element={<LandingPageBnb1CTA />} />
            <Route path="/_2airbnbs" element={<LandingPageBnb2CTA />} />
            <Route path="/_3airbnbs" element={<LandingPageBnb3CTA />} />
            <Route path="/_4airbnbs" element={<LandingPageBnb4CTA />} />
            <Route path="/book-call-1" element={<BookingPage1 />} />
            <Route path="/apply" element={<FreelanceOnboarding />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/guest-pages" element={<div>Guest Pages</div>} />
            <Route path="/auto-agency" element={<div>Auto Agency</div>} />
            <Route path="/bonus-links" element={<div>Bonus Links</div>} />
            <Route path="/teamdashboard" element={<ProtectedRoute element={<TeamDashboard />} />} />
          </Routes>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
