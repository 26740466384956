import React, { useState } from 'react';
import './FreelanceSteps.css';

const MultiTextInputStep = ({ question, onAnswer }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    instagram: '',
    termsAccepted: false, // Checkbox state
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const isValidEmail = formData.email.includes('@') && formData.email.includes('.');
  const isButtonDisabled = !formData.name || !isValidEmail || !formData.termsAccepted;

  const handleNext = () => {
    onAnswer(formData);
  };

  return (
    <div className="quiz-step-container">
      <h2>{question}</h2>
      <p className="quiz-subtext">Let us know where we can reach you and take the first step towards earning with BonusClub.</p>

      <div className="quiz-input-group">
        <label className="quiz-pseudofield">
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Full Name"
            onChange={handleChange}
          />
          <img className="quiz-icon" src="./img/user.svg" alt="Name Icon" />
        </label>
      </div>

      <div className="quiz-input-group">
        <label className="quiz-pseudofield">
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder="Email Address"
            onChange={handleChange}
          />
          <img className="quiz-icon" src="./img/envelope.svg" alt="Email Icon" />
        </label>
      </div>

      <div className="quiz-input-group">
        <label className="quiz-pseudofield">
          <input
            type="text"
            name="whatsapp"
            value={formData.whatsapp}
            placeholder="WhatsApp Number (Optional)"
            onChange={handleChange}
          />
          <img className="quiz-icon" src="./img/whatsapp.svg" alt="WhatsApp Icon" />
        </label>
      </div>

      <div className="quiz-input-group">
        <label className="quiz-pseudofield">
          <input
            type="text"
            name="instagram"
            value={formData.instagram}
            placeholder="Instagram (Optional)"
            onChange={handleChange}
          />
          <img className="quiz-icon" src="./img/instagram.svg" alt="Instagram Icon" />
        </label>
      </div>

      <div className="quiz-checkbox-group">
        <label>
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
          />
          I agree to the <a href="/terms" target="_blank">Terms of Use</a>
        </label>
      </div>

      <div className="quiz-button-group">
        <button className="yes2-button" onClick={handleNext} disabled={isButtonDisabled}>
          Finish
        </button>
      </div>
    </div>
  );
};

export default MultiTextInputStep;
