import React, { useState } from 'react';
import '../Landingpage/FAQPage.css';

const faqs = [
  { question: 'What is BonusClub?', answer: 'BonusClub is a platform that helps hotels and Airbnb hosts improve their guest experience while increasing their revenue. As a BonusClub partner, you help bring this solution to new hosts and earn commissions.' },
  { question: 'How do I earn money at BonusClub?', answer: 'Your job is simple: introduce hotels and Airbnb hosts to BonusClub, spark their interest, and close the deal. We handle the full setup—your focus is on supporting your clients and keeping them happy. And the best part? From your very first sale, you start building passive income.'},
  { question: 'Is this a real job or a side hustle?', answer: 'It can be both! You can work full-time or part-time, depending on how much you want to earn. The more effort you put in, the higher your income.' },
  { question: 'Do I need sales experience?', answer: 'No experience is required! We provide training, scripts, and support to help you succeed—even if you`ve never sold anything before.' },
  { question: 'Can I do this while traveling?', answer: 'Yes! As long as you have a phone and internet connection, you can work from anywhere in the world.' },
  { question: 'How and when do I get paid?', answer: 'You get paid fast and hassle-free. Every time a customer makes a payment for their service, your commission is sent to you within two days. You can receive your money via PayPal, bank transfer, or other available options—whichever works best for you.' },
  { question: 'What if I need help or have questions?', answer: 'We have a support team and a community of other partners who are happy to help. You`re never alone in this.' },
  { question: 'Is this like Crypto, Social Media Marketing, or just another online hype?', answer: 'No. BonusClub is a real business with a real product that hotels and Airbnb hosts actually need. Unlike crypto schemes or social media marketing agencies where everyone is selling the same thing, you’ll be offering an exclusive, tailor-made system designed for the hospitality industry.' },
];

const FAQPageApply = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-page-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question-container" onClick={() => toggleFAQ(index)}>
              <p className="faq-question">{faq.question}</p>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              <p style={{lineHeight: '2', letterSpacing: '1px', marginTop: '-10px'}}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPageApply;
