import React from 'react';
import './FreelanceSteps.css';

const YesNoStep = ({ question, subText, onAnswer }) => {
  return (
    <div className="quiz-step-container">
      <h2>{question}</h2>
      <div className="quiz-button-group">
        <button className="yes-button" onClick={() => onAnswer('yes')}>
          Yes
        </button>
        <button className="no-button" onClick={() => onAnswer('no')}>
          No
        </button>
      </div>
    </div>
  );
};

export default YesNoStep;
