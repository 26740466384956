import React, { useState, useEffect, useRef } from 'react';
import './PasswordProtection.css';

const PasswordProtection = ({ onAuthenticated }) => {
  // Anzahl der Passwortzeichen
  const passwordLength = 4; // "2030" hat 4 Zeichen
  const correctPassword = '2030';

  // State für die eingegebenen Passwortzeichen und Anzeige des Buttons
  const [passwordChars, setPasswordChars] = useState(Array(passwordLength).fill(''));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showError, setShowError] = useState(false);
  // State für den Fokus-Status jedes Felds
  const [focusedField, setFocusedField] = useState(null);
  
  // Refs für die Eingabefelder - initialisieren mit leeren Array der richtigen Länge
  const inputRefs = useRef(Array(passwordLength).fill(null));
  
  // Verarbeitet die Eingabe in einem Eingabefeld
  const handleInputChange = (index, value) => {
    // Aktualisiere das aktuelle Zeichenarray
    const newPasswordChars = [...passwordChars];
    newPasswordChars[index] = value;
    setPasswordChars(newPasswordChars);
    
    // Wenn ein Zeichen eingegeben wurde, fokussiere das nächste Feld
    if (value !== '' && index < passwordLength - 1) {
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
    
    // Überprüfe das Passwort nach der Eingabe des letzten Zeichens
    if (index === passwordLength - 1 && value !== '') {
      checkPassword(newPasswordChars.join(''));
    }
    
    // Fehlermarkierung entfernen
    if (showError) {
      setShowError(false);
    }
  };

  // Verarbeitet Tastenanschläge (für Löschtaste und Navigation)
  const handleKeyDown = (index, e) => {
    // Bei Rücktaste/Entfernen und leerem Feld zum vorherigen Feld wechseln
    if ((e.key === 'Backspace' || e.key === 'Delete') && 
        passwordChars[index] === '' && 
        index > 0) {
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Passwort überprüfen
  const checkPassword = (enteredPassword) => {
    if (enteredPassword.toLowerCase() === correctPassword.toLowerCase()) { // Case-insensitive vergleich
      setIsAuthenticated(true);
      setShowError(false);
    } else {
      setShowError(true);
      // Alle Felder leeren
      setPasswordChars(Array(passwordLength).fill(''));
      // Fokus auf erstes Feld setzen
      setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 10);
    }
  };

  // Eingabefelder für Paste-Ereignis
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, passwordLength);
    
    const newPasswordChars = [...passwordChars];
    
    // Fülle die Array-Elemente mit den eingefügten Zeichen
    for (let i = 0; i < pastedData.length; i++) {
      newPasswordChars[i] = pastedData[i];
    }
    
    setPasswordChars(newPasswordChars);
    
    // Überprüfe das Passwort, wenn genügend Zeichen eingefügt wurden
    if (pastedData.length === passwordLength) {
      checkPassword(pastedData);
    } else if (pastedData.length > 0) {
      // Fokussiere das nächste leere Feld
      const nextEmptyIndex = Math.min(pastedData.length, passwordLength - 1);
      if (inputRefs.current[nextEmptyIndex]) {
        inputRefs.current[nextEmptyIndex].focus();
      }
    }
  };

  // Behandelt den Fokus auf einem Eingabefeld
  const handleFocus = (index) => {
    setFocusedField(index);
  };

  // Behandelt das Verlassen eines Eingabefelds
  const handleBlur = () => {
    setFocusedField(null);
  };

  // Behandle den Klick auf den "Enter Bonusclub" Button
  const handleEnterClick = () => {
    localStorage.setItem('isAuthenticated', 'true');
    onAuthenticated();
  };

  return (
    <div className="passwordProtectionContainer">
      <div className="passwordProtectionLogoCircle">
        <img src="../img/bonusclub_icon.svg" alt="Bonus Club Logo" />
      </div>
      
      <div className="passwordProtectionSection">
        <h2 className="passwordProtectionHeading">Enter Code to Access BonusClub</h2>
        
        <div className="passwordProtectionInputWrapper">
          {Array.from({ length: passwordLength }, (_, index) => (
            <div key={index} className="passwordProtectionInputContainer">
              <input
                ref={el => inputRefs.current[index] = el}
                type="text"
                maxLength={1}
                className={`passwordProtectionCharInput ${showError ? 'passwordProtectionError' : ''}`}
                value={passwordChars[index] || ''}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onFocus={() => handleFocus(index)}
                onBlur={handleBlur}
                onPaste={index === 0 ? handlePaste : null}
                autoFocus={index === 0}
              />
              {(!passwordChars[index] || passwordChars[index] === '') && 
                focusedField !== index && 
                <span className="passwordProtectionPlaceholderStar">*</span>}
            </div>
          ))}
        </div>
      </div>
      
      {isAuthenticated && (
        <button className="passwordProtectionEnterButton" onClick={handleEnterClick}>
          Enter Bonusclub
        </button>
      )}
    </div>
  );
};

export default PasswordProtection; 