import React, { useState } from 'react';
import './StartPage.css';
import { numbersInWords } from './numbersInWords'; // Falls in externer Datei
import ApplyStartInfo1 from './ApplyStartInfo1';
import ApplyStartInfo2 from './ApplyStartInfo2';
import ApplyStartInfo3 from './ApplyStartInfo3';
import ApplyStartInfoBackground from './ApplyStartInfoBackground';
import ApplyStartInfo5 from './ApplyStartInfo5';
import FAQPageApply from './FAQPageApply';
import Footer from '../Landingpage/Footer';
import QuizHeader from './QuizHeader';

const pricing = {
  "Big Hotels": { oneTime: 420, monthly: 39 },
  "Small Hotels": { oneTime: 320, monthly: 29 },
  "Airbnbs": { oneTime: 180, monthly: 9 }
};

const StartPage = ({ onStart }) => {
  const [count, setCount] = useState(8);
  const [target, setTarget] = useState("Small Hotels");

  const handleChange = (change) => {
    setCount((prev) => Math.max(1, Math.min(30, prev + change)));
  };

  const handleTargetChange = (direction) => {
    const options = ["Big Hotels", "Small Hotels", "Airbnbs"];
    const currentIndex = options.indexOf(target);
    const nextIndex = (currentIndex + direction + options.length) % options.length;
    setTarget(options[nextIndex]);
  };

  const earningsOneTime = pricing[target].oneTime * count;
  const earningsMonthly = pricing[target].monthly * count;

  // Hier wandeln wir die Zahl in ein ausgeschriebenes Wort um
  const spelledCount = numbersInWords[count];

  return (
      <div className="quiz_start_page">
      <QuizHeader onApply={() => onStart({ sales: count, target })} />
    <div className="freelance_start_page">
      <p className="start-introText">Join as a BonusClub Partner.</p>
      
      <h1 className="quiz-start-heading">
        Sell{" "}
        <div className="countContainer">
          <button className="start-btn minusBtn" onClick={() => handleChange(-1)}>
          <img
              style={{ rotate: '0deg', marginBottom: '0px', marginLeft: '0px', width: '12px' }}
              src="./img/minus.svg"
              alt=""
            />

          </button>
          <span className="countValue">{spelledCount}</span>
          <button className="start-btn plusBtn" onClick={() => handleChange(1)}>
          <img
              style={{ rotate: '270deg', marginBottom: '0px', marginLeft: '0px', width: '14px'}}
              src="./img/plus.svg"
              alt=""
            />

          </button>
        </div>
        {" "}BonusClub Memberships to{" "}
        <div className="targetContainer">
          <button className="start-btn prevTargetBtn" onClick={() => handleTargetChange(-1)}>
            <img
              style={{ rotate: '-180deg', marginBottom: '0px', marginRight: '3px', width: '15px' }}
              src="./img/rechts.svg"
              alt=""
            />
          </button>
          <span className="targetValue">{target}</span>
          <button className="start-btn nextTargetBtn" onClick={() => handleTargetChange(1)}>
            <img
              style={{ rotate: '0deg', marginBottom: '0px', marginLeft: '3px', width: '15px' }}
              src="./img/rechts.svg"
              alt=""
            />
          </button>
        </div>
        {" "}

          and earn <div style={{ color: 'var(--blue)', display: 'inline-block' }}> ${earningsOneTime}</div> one time +  <div style={{ color: 'var(--blue)', display: 'inline-block' }}>${earningsMonthly} </div> every month.
      </h1>

      <button 
        className="start-continueBtn"
        onClick={() => onStart({ sales: count, target })}
      >
        Apply for a job (2 Min)
      </button>
    </div>

    <ApplyStartInfoBackground backgroundImage="./img/club_girls.webp" />

    <ApplyStartInfo1
        mainTitle="Work Anywhere, Earn Anytime."
        mainSubtitle="With Bonus Club, you can make money from anywhere – whether you're chilling at the beach, exploring new cities, or just working from your favorite coffee shop. No bosses, no schedules, just freedom."
        boxes={[
          {
            image: "./img/world.jpg",
            title: "Make Money Anywhere – Even While Traveling",
            text: "Why work a “real job” when you can earn big money from anywhere? Whether you’re surfing in Bali or sipping coffee in Paris, your income travels with you."
          },
          {
            image: "./img/beach.jpg",
            title: "A Real Product. A Real Income. No Fairytales.",
            text: "No weird side hustles, no selling random junk. You’re offering a product that actually helps businesses – and you earn for every sale. Hotels and BnB owners need this. You make sure they get it."
          },
          {
            image: "./img/relax.jpg",
            title: "Work With Hotels & BnB Owners Like a Pro",
            text: "Connect with real businesses, build industry experience, and get paid for providing real value – all while staying in full control of your time."
          }
        ]}
      />


<ApplyStartInfo2
      mainTitle="Enjoy Freedom. Skip being broke."
      mainSubtitle="You don’t have to struggle to enjoy life. Travel, explore, and do what you love - without constantly worrying about money. With BonusClub, you can earn on your own terms."
      leftTitle="99% of Travelers"
      leftImage="./img/worried-traveler.jpg"
      leftPoints={[
        "Lives paycheck to paycheck",
        "Tied to one location",
        "Sells random junk",
        "Works for others",
        "Depends on luck",
      ]}
      rightTitle="BonusClub Partner"
      rightImage="./img/traveler.jpg"
      rightPoints={[
        "Steady monthly income",
        "Works from anywhere, anytime",
        "Helps real people",
        "Is his own boss",
        "Earns on autopilot"
      ]}
    />

<ApplyStartInfo3
      mainTitle="What you can expect when working with BonusClub."
      items={[
        {
          image: './img/verified_2.webp',
          title: ' Real Income, No Fake Promises',
          text: 'You sell a product that Hotel Owners actually need. No hype, just results.'
        },
        {
          image: './img/verified_2.webp',
          title: 'No Experience? No Problem.',
          text: 'We provide step-by-step guidance so you can start earning fast.'
        },
        {
          image: './img/verified_2.webp',
          title: ' Zero Cost',
          text: 'You don’t have to invest money to start. Just your time and effort.'
        },
        {
          image: './img/verified_2.webp',
          title: 'Sell a Product That Works',
          text: 'Hotels and BnB owners actually benefit from BonusClub, making it an easy sell.'
        },
        {
          image: './img/verified_2.webp',
          title: 'Exclusive Partner Support',
          text: 'You’re not alone! We provide help, tips, and strategies to make selling easy.'
        },
        {
          image: './img/verified_2.webp',
          title: 'Earn on Autopilot',
          text: 'Once you land a client, you keep earning from their subscription every month.'
        },
        {
          image: './img/verified_2.webp',
          title: 'No False Promises – Effort In, Money Out',
          text: 'If you put in the work, you can earn serious money. But if you`re looking to get paid for being lazy, this isn’t for you.'
        }
      ]}
    />
    
<ApplyStartInfo5
  heading="We're Hiring:"
  location="Worldwide"
  position="Junior Sales Manager"
  requirements={[
    "Basic computer skills",
    "Confident and friendly personality",
    "Speaks and writes English well",
    "Some sales or customer service experience helps",
    "Enjoys travel and making connections",
    "Honest, responsible, and hardworking",
    "Wants to build long-term income",
    "Can keep a team organized and focused"

  ]}
  iconPath="./img/user_2.svg"
  onApply={() => onStart({ sales: count, target })}
/>

<FAQPageApply />
<Footer />


    </div>

  );
};

export default StartPage;
