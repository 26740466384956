import React, { useState } from 'react';
import { database, ref, push, set } from '../firebaseConfig';

// Import der Komponenten
import YesNoStep from './YesNoStep';
import TextInputStep from './TextInputStep';
import MultiTextInputStep from './MultiTextInputStep';
import ButtonSelectStep from './ButtonSelectStep';
import CountrySelectStep from './CountrySelectStep';
import AgeQuestionStep from './AgeQuestionStep';
import ConfirmationPage from './ConfirmationPage';
import StartPage from './StartPage';
import ProgressBar from './ProgressBar'; // Import der ProgressBar
import Navigation2 from '../Landingpage/Navigation2';



const StepRenderer = ({ step, onAnswer }) => {
  switch (step.type) {
    case 'yesNo':
      return <YesNoStep question={step.question} subText={step.subText} onAnswer={(value) => onAnswer(step.id, value)} />;
    case 'textInput':
      return <TextInputStep question={step.question} placeholder={step.placeholder} onAnswer={(value) => onAnswer(step.id, value)} />;
    case 'multiText':
      return <MultiTextInputStep question={step.question} onAnswer={(value) => onAnswer(step.id, value)} />;
    case 'buttonSelect':
      return <ButtonSelectStep question={step.question} subText={step.subText} buttons={step.buttons} multiSelect={step.multiSelect} onAnswer={(value) => onAnswer(step.id, value)} />;
    case 'countrySelect':
      return <CountrySelectStep question={step.question} subText={step.subText} onAnswer={(value) => onAnswer(step.id, value)} />;
    case 'ageQuestion':
      return <AgeQuestionStep question={step.question} onAnswer={(value) => onAnswer(step.id, value)} />;
    default:
      return null;
  }
};

const FreelanceOnboarding = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const steps = [
    // 3x Yes/No Questions (Yes = 2, No = 0)
    { id: 'step1', type: 'yesNo', question: 'Do you have access to a computer with internet connection?', subText: 'Optional subtext', scores: { Yes: 1, No: 0 } },
    { id: 'step2', type: 'yesNo', question: 'Can you make calls and send messages to potential clients?', subText: 'Optional subtext', scores: { Yes: 2, No: 0 } },
    { id: 'step3', type: 'yesNo', question: 'Do you have experience in customer communication?', subText: 'Optional subtext', scores: { Yes: 2, No: 0 } },
  
    // Age Question (Higher score for ideal age range)
    { id: 'step4', type: 'ageQuestion', question: 'How old are you?', scores: { '18-24': 3, '25-35': 2, '36+': 1 } },
  
    // Work Hours Selection (More commitment = Higher score)
    {
      id: 'step5',
      type: 'buttonSelect',
      question: 'How many hours do you want to work per week?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: '10 hours', label: '10 hours', score: 1 },
        { value: '20 hours', label: '20 hours', score: 2 },
        { value: '40 hours', label: '40 hours', score: 4 }
      ]
    },
  
    // Country Selection (Scoring based on accessibility to market)
    { id: 'step6', type: 'countrySelect', question: 'Where are you currently located?', subText: 'Select your country from the list below.', scores: {} },
  
    // Willingness to reach out to clients (Higher willingness = Higher score)
    {
      id: 'step7',
      type: 'buttonSelect',
      question: 'Are you willing to reach out to new potential clients?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: 'I Enjoy Networking', label: 'Yes, I enjoy networking', score: 4 },
        { value: 'I need Guidance', label: 'Yes, but I need guidance', score: 2 },
        { value: 'No', label: 'No, I prefer passive income', score: 0 }
      ]
    },
  
    // Work Ethic (Self-starter scores higher)
    {
      id: 'step8',
      type: 'buttonSelect',
      question: 'How would you describe your work ethic?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: "I'm a self-starter who works hard", label: "I'm a self-starter who works hard", score: 4 },
        { value: "I need structure but get things done", label: "I need structure but get things done", score: 2 },
        { value: "I struggle with staying motivated", label: "I struggle with staying motivated", score: 0 }
      ]
    },
  
    // Current Situation (More flexibility = Higher score)
    {
      id: 'step9',
      type: 'buttonSelect',
      question: 'Describe your current situation?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: "Student", label: "I'm a student", score: 3 },
        { value: "I have a full-time job", label: "I have a full-time job", score: 0 },
        { value: "I have a part-time job", label: "I have a part-time job", score: 1 },
        { value: "I'm currently unemployed", label: "I'm looking for a job", score: 2 }
      ]
    },
  
    // Previous Sales Experience (More experience = Higher score)
    {
      id: 'step10',
      type: 'buttonSelect',
      question: 'Have you ever done sales work before?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: "Yes, a lot", label: "Yes, a lot", score: 5 },
        { value: "Yes, but not much", label: "Yes, but not much", score: 3 },
        { value: "No, but I'm excited to learn", label: "No, but I'm excited to learn", score: 1 }
      ]
    },
  
    // Tool Knowledge (The more tools they know, the better)
    {
      id: 'step11',
      type: 'buttonSelect',
      question: 'Which tools have you used before?',
      subText: 'Select all that apply',
      multiSelect: true,
      buttons: [
        { value: "CRM", label: "CRM Systems (Hubspot, Pipedrive, Salesforce,..)", score: 3 },
        { value: "Email Marketing", label: "Email marketing tools (e.g., Mailchimp, ActiveCampaign)", score: 3 },
        { value: "Google Suite", label: "Google Suite (Docs, Sheets, Gmail, Drive)", score: 1 },
        { value: "Social Media", label: "Social media platforms (Instagram, Facebook, LinkedIn)", score: 1 },
        { value: "Nothing", label: "I haven't used any of these, but I'm willing to learn", score: 0 }
      ]
    },
  
    // Start Date (Sooner = Higher score)
    {
      id: 'step12',
      type: 'buttonSelect',
      question: 'When would you want to start?',
      subText: 'Only one can be selected',
      multiSelect: false,
      buttons: [
        { value: "Right away!", label: "As fast as possible", score: 4 },
        { value: "In 1 Month", label: "In 1 Month", score: 2 },
        { value: "I don't know yet", label: "I don't know yet", score: 0 }
      ]
    },
  
    // Final Step: Entering Details
    { id: 'step13', type: 'multiText', question: 'Final Step: Enter Your Details' }
  ];
  
  const [totalScore, setTotalScore] = useState(0);


  const handleAnswer = (stepId, userValue) => {
    setAnswers((prev) => {
      const updatedAnswers = { ...prev, [stepId]: userValue };
      const currentStep = steps.find(step => step.id === stepId);
      let newScore = 0;

      if (currentStep) {
        if (currentStep.type === 'yesNo') {
          newScore = currentStep.scores[userValue] || 0;
        } else if (currentStep.type === 'buttonSelect') {
          if (currentStep.multiSelect) {
            newScore = userValue.reduce((acc, val) => acc + (currentStep.buttons.find(b => b.value === val)?.score || 0), 0);
          } else {
            newScore = currentStep.buttons.find(b => b.value === userValue)?.score || 0;
          }
        } else if (currentStep.type === 'ageQuestion') {
          newScore = currentStep.scores[userValue] || 0;
        }
      }

      setTotalScore(prevScore => prevScore + newScore);

      if (currentStepIndex === steps.length - 1) {
        handleSubmit(updatedAnswers, totalScore + newScore);
      } else {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }

      return updatedAnswers;
    });
  };

  const handleSubmit = async (finalAnswers, totalScore) => {
    const newEntryRef = push(ref(database, 'freelanceOnboarding'));
    await set(newEntryRef, { 
      ...finalAnswers, 
      totalScore: totalScore || 0,  // Stelle sicher, dass totalScore nicht undefined ist
      timestamp: Date.now() // Füge den Timestamp hinzu
    });
    setSubmitted(true);
  };

  const handleStartQuiz = (data) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setAnswers(data);
    setCurrentStepIndex(0);
  };

  if (submitted) return <ConfirmationPage />;

  return (
    <>
       <div className="apply-quiz-head" style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: '40px',
      paddingTop: '40px',
      marginBottom: '-40px',
      backgroundColor: 'var(--background)',
    }}>
      <img 
        src="./img/bonusclub_icon.svg" 
        alt="My Logo" 
        style={{ height: '40px' }} 
      />
    </div>
      {currentStepIndex === -1 ? (
        <StartPage onStart={handleStartQuiz} />
      ) : (
        <StepRenderer step={steps[currentStepIndex]} onAnswer={handleAnswer} />
      )}
      <ProgressBar currentStep={currentStepIndex} totalSteps={steps.length} />
    </>
  );
};

export default FreelanceOnboarding;
