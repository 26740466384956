import React, { useState, useEffect } from 'react';
import { database, ref, onValue } from '../firebaseConfig';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import './QuizDashboard.css';
import DownloadReport from './DownloadReport';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const QuizDashboard = () => {
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const quizRef = ref(database, 'freelanceOnboarding');
    onValue(quizRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedData = Object.entries(data).map(([id, value]) => ({
          id,
          ...value
        }));
        setQuizData(formattedData);
      }
      setLoading(false);
    });
  }, []);

  const getAverageScore = () => {
    if (quizData.length === 0) return 0;
    const validScores = quizData.filter(entry => typeof entry.totalScore === 'number');
    if (validScores.length === 0) return 0;
    return (validScores.reduce((acc, curr) => acc + curr.totalScore, 0) / validScores.length).toFixed(1);
  };

  const getWorkHoursDistribution = () => {
    const distribution = quizData.reduce((acc, curr) => {
      const hours = curr.step5 || 'Nicht angegeben';
      acc[hours] = (acc[hours] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(distribution),
      datasets: [{
        data: Object.values(distribution),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      }]
    };
  };

  const getToolsUsage = () => {
    const toolsCount = quizData.reduce((acc, curr) => {
      if (Array.isArray(curr.step11)) {
        curr.step11.forEach(tool => {
          acc[tool] = (acc[tool] || 0) + 1;
        });
      }
      return acc;
    }, {});

    return {
      labels: Object.keys(toolsCount),
      datasets: [{
        label: 'Anzahl der Nutzer',
        data: Object.values(toolsCount),
        backgroundColor: '#36A2EB',
      }]
    };
  };

  if (loading) {
    return <div className="quiz-dashboard-loading">Laden...</div>;
  }

  return (
    <div className="quiz-dashboard-container">
      <div className="quiz-dashboard-stats-grid">
        <div className="quiz-dashboard-stat-card quiz-dashboard-stat-card-blue">
          <h3 className="quiz-dashboard-stat-title">Gesamtbewerbungen</h3>
          <p className="quiz-dashboard-stat-value">{quizData.length}</p>
        </div>
        <div className="quiz-dashboard-stat-card quiz-dashboard-stat-card-green">
          <h3 className="quiz-dashboard-stat-title">Durchschnittliche Punktzahl</h3>
          <p className="quiz-dashboard-stat-value">{getAverageScore()}</p>
        </div>
        <div className="quiz-dashboard-stat-card quiz-dashboard-stat-card-purple">
          <h3 className="quiz-dashboard-stat-title">Letzte Bewerbung</h3>
          <p className="quiz-dashboard-stat-value">
            {quizData.length > 0 && quizData[quizData.length - 1].timestamp 
              ? new Date(quizData[quizData.length - 1].timestamp).toLocaleDateString('de-DE', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })
              : '-'}
          </p>
        </div>
      </div>

      <div className="quiz-dashboard-charts-grid">
        <div className="quiz-dashboard-chart-container">
          <h3 className="quiz-dashboard-chart-title">Arbeitsstundenverteilung</h3>
          <div className="quiz-dashboard-chart-wrapper">
            <Pie data={getWorkHoursDistribution()} />
          </div>
        </div>
        <div className="quiz-dashboard-chart-container">
          <h3 className="quiz-dashboard-chart-title">Tool-Erfahrung</h3>
          <div className="quiz-dashboard-chart-wrapper">
            <Bar 
              data={getToolsUsage()}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="quiz-dashboard-table-section">
        <h3 className="quiz-dashboard-table-title">Neueste Bewerbungen</h3>
        <div className="quiz-dashboard-table-container">
          <table className="quiz-dashboard-table">
            <thead>
              <tr className="quiz-dashboard-table-header">
                <th>Datum</th>
                <th>Name</th>
                <th>Email</th>
                <th>Punktzahl</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {quizData.slice(-5).reverse().map((entry) => (
                <tr key={entry.id} className="quiz-dashboard-table-row">
                  <td className="quiz-dashboard-table-cell">
                    {entry.timestamp 
                      ? new Date(entry.timestamp).toLocaleDateString('de-DE', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                        })
                      : '-'}
                  </td>
                  <td className="quiz-dashboard-table-cell">{entry.step13?.name || '-'}</td>
                  <td className="quiz-dashboard-table-cell">{entry.step13?.email || '-'}</td>
                  <td className="quiz-dashboard-table-cell">{entry.totalScore || 0}</td>
                  <td className="quiz-dashboard-table-cell">
                    <DownloadReport entry={entry} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default QuizDashboard; 