// src/Admin/TeamDashboard.js
import React, { useState } from 'react';
import SetSpecialOffer from '../Landingpage/SpecialOffer';
import QuizDashboard from './QuizDashboard';

const TeamDashboard = () => {
  const [activeComponent, setActiveComponent] = useState('quiz'); // 'quiz' oder 'offers'

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Team Dashboard</h1>
        <div className="space-x-4">
          <button
            className={`px-4 py-2 rounded-lg ${
              activeComponent === 'quiz'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveComponent('quiz')}
          >
            Quiz Übersicht
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              activeComponent === 'offers'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveComponent('offers')}
          >
            Special Offers
          </button>
        </div>
      </div>

      {activeComponent === 'quiz' ? <QuizDashboard /> : <SetSpecialOffer />}
    </div>
  );
};

export default TeamDashboard;
