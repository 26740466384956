import React, { useState } from 'react';
import './FreelanceSteps.css';

const CountrySelectStep = ({ question, subText, onAnswer }) => {
  const [selectedCountry, setSelectedCountry] = useState('');

  // Hardcodierte Liste von Ländern in der Komponente
  const countries = [
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Dubai (UAE)', label: 'Dubai (UAE)' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'England (UK)', label: 'England (UK)' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Hawaii (USA)', label: 'Hawaii (USA)' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Scotland (UK)', label: 'Scotland (UK)' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Korea', label: 'South Korea' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'USA', label: 'USA' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Other', label: 'Other' }
];

  const handleNext = () => {
    onAnswer(selectedCountry);
  };

  return (
    <div className="quiz-step-container">
      <h2>{question}</h2>
      {subText && <p>{subText}</p>}
<div className="quiz-country-container">
      <select
        value={selectedCountry}
        onChange={(e) => setSelectedCountry(e.target.value)}
        className="quiz-country-selector"
      >
        <option value="" disabled>
          Select Country
        </option>
        {countries.map((country) => (
          <option key={country.value} value={country.value}>
            {country.label}
          </option>
        ))}
      </select>
      </div>
      <div className="quiz-button-group">
      <div style={{ marginTop: '1rem' }}>
        <button
          className="yes2-button"
          onClick={handleNext}
          disabled={!selectedCountry}
        >
          Next
        </button>
      </div>
      </div>
    </div>
  );
};

export default CountrySelectStep;
