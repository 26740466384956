import React, { useState } from 'react';
import './FreelanceSteps.css';

const TextInputStep = ({ question, placeholder, onAnswer }) => {
  const [inputValue, setInputValue] = useState('');

  const handleNext = () => {
    onAnswer(inputValue);
  };

  return (
    <div className="quiz-step-container">
      <h2>{question}</h2>
      <input
        type="text"
        value={inputValue}
        placeholder={placeholder || ''}
        onChange={(e) => setInputValue(e.target.value)}
        style={{ width: '80%', padding: '8px', marginTop: '1rem' }}
      />
      <div style={{ marginTop: '1rem' }}>
        <button className="yes-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default TextInputStep;
