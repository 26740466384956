import React from 'react';
import './ApplyStartInfo.css';

const ApplyStartInfoBackground = ({ backgroundImage }) => {
  return (
    <div
      className="apply-info4-wrapper"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Falls du hier noch Text, Overlays etc. anzeigen möchtest,
          kannst du sie entweder als Kinder (props.children) einbauen
          oder einfach hier statisch/hardcoded einfügen. */}
    </div>
  );
};

export default ApplyStartInfoBackground;
