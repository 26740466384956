import React, { useState } from 'react';

const AgeQuestionStep = ({ question, initialAge = 22, onAnswer }) => {
  const [age, setAge] = useState(initialAge);

  const handleIncrease = () => {
    setAge((prev) => prev + 1);
  };

  const handleDecrease = () => {
    if (age > 1) setAge((prev) => prev - 1);
  };

  return (
    <div className="quiz-step-container" style={{ textAlign: 'center', padding: '20px' }}>
      <h2 id="quiz_age_font" >{question}</h2>
      <div className="quiz-number-counter">
        <button onClick={handleDecrease} style={{ fontSize: '24px', padding: '5px 15px' }}><img src="./img/minus.svg" alt="" /></button>
        <span>{age}</span>
        <button onClick={handleIncrease} style={{ fontSize: '24px', padding: '5px 15px' }}><img src="./img/plus.svg" alt="" /></button>
      </div>
      <div className="quiz-button-group">
      <button className="yes-button" onClick={() => onAnswer(age)} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '18px' }}>
        Next
      </button>
      </div>
    </div>
  );
};

export default AgeQuestionStep;
