import React, { useEffect, useRef } from 'react';
import './ApplyStartInfo.css';

const ApplyStartInfo1 = ({
  mainTitle,
  mainSubtitle,
  boxes = []
}) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-visible');
        }
      },
      {
        threshold: 0.1
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div className="apply-info-wrapper">
      <h5 className="apply-now-badge">Apply Now</h5>
      <h2 className="apply-info-title" ref={titleRef}>{mainTitle}</h2>
      
      {/* Untertitel / kurzer Absatz */}
      <p className="apply-info-subtitle">{mainSubtitle}</p>

      {/* Container für die 3 Boxen */}
      <div className="apply-info-box-container">
        {boxes.map((box, index) => (
          <div className="apply-info-box" key={index}>
            {/* Optionales Icon / Bild oben */}
            {box.image && (
              <div className="apply-info-box-image-wrapper">
                <img 
                  src={box.image} 
                  alt={`Box ${index + 1}`} 
                  className="apply-info-box-image" 
                />
              </div>
            )}
            {/* Titel in der Box */}
            <h3 className="apply-info-box-title">{box.title}</h3>
            
            {/* Text in der Box */}
            <p className="apply-info-box-text">{box.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyStartInfo1;
