import React, { useRef, useEffect } from 'react';
import './ApplyStartInfo.css';

const ApplyStartInfo5 = ({
  heading = "We are Looking for",
  location = "Worldwide",
  position = "Junior Sales Manager",
  requirements = [],
  iconPath = "./img/user_2.svg",
  onApply
}) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-visible');
        }
      },
      {
        threshold: 0.1
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  const handleCardClick = () => {
    if (onApply) {
      onApply();
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    if (onApply) {
      onApply();
    }
  };

  return (
    <div className="apply-info5-wrapper">
      <h2 className="apply-info5-heading" ref={titleRef}>{heading}</h2>

      <div className="apply-info5-card" onClick={handleCardClick}>
        <div className="apply-info5-card-header">
          <span className="apply-info5-location">Location: {location}</span>
          <h3 className="apply-info5-position">{position}</h3>
          <img
            src={iconPath}
            alt="User Icon"
            className="apply-info5-icon"
          />
        </div>

        <div className="apply-info5-card-content">
          <h4 className="apply-info5-requirements-title">Requirements</h4>
          <ul className="apply-info5-requirements-list">
            {requirements.map((req, index) => (
              <li key={index}>
                <img
                  src="./img/tick.svg"
                  alt="Check Icon"
                  className="apply-info5-tick-icon"
                />
                {req}
              </li>
            ))}
          </ul>
        </div>

        <div className="apply-info5-card-footer">
          <button 
            className="apply-info5-apply-button"
            onClick={handleButtonClick}
          >
            Apply Here
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyStartInfo5;
