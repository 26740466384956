import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
  // Falls currentStep -1 ist (z.B. StartPage), zeigen wir 0%
  const progressPercentage = currentStep >= 0 ? ((currentStep + 1) / totalSteps) * 100 : 0;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '8px',
        backgroundColor: '#e0e0e0',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: `${progressPercentage}%`,
          height: '100%',
          backgroundColor: 'var(--blue)',
          transition: 'width 0.3s ease',
        }}
      />
    </div>
  );
};

export default ProgressBar;
