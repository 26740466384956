import React, { useEffect, useState } from 'react';
import './QuizHeader.css';

const QuizHeader = ({ onApply }) => {
  const [showHeader, setShowHeader] = useState(false);
  const [targetDate, setTargetDate] = useState(getNextMondayMidnight());

  // Einzelne State-Variablen für Tage, Stunden, Minuten, Sekunden
  const [daysLeft, setDaysLeft] = useState('0');
  const [hoursLeft, setHoursLeft] = useState('00');
  const [minutesLeft, setMinutesLeft] = useState('00');
  const [secondsLeft, setSecondsLeft] = useState('00');

  // 1) Countdown-Logik
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      if (distance <= 0) {
        // Countdown abgelaufen -> Nächster Montag
        setTargetDate(getNextMondayMidnight());
      } else {
        // Tage, Stunden, Minuten, Sekunden berechnen
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((distance / (1000 * 60)) % 60);
        const seconds = Math.floor((distance / 1000) % 60);

        // Mit führenden Nullen formatieren (nur Stunden, Minuten, Sekunden)
        setDaysLeft(String(days)); 
        setHoursLeft(String(hours).padStart(2, '0'));
        setMinutesLeft(String(minutes).padStart(2, '0'));
        setSecondsLeft(String(seconds).padStart(2, '0'));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  // 2) Scroll-Listener für Ein-/Ausblenden ab 50px
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // 3) Funktion: Nächster Montag, 00:00:00
  function getNextMondayMidnight() {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0=Sonntag, 1=Montag, etc.
    let daysUntilMonday = (1 - dayOfWeek + 7) % 7;
    if (daysUntilMonday === 0) {
      // Heute ist Montag -> auf nächsten Montag +7 Tage
      daysUntilMonday = 7;
    }
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + daysUntilMonday,
      0,
      0,
      0
    );
  }

  // 4) Klick-Handler für "Apply Now"
  const handleApplyClick = () => {
    if (onApply) {
      onApply();
    }
  };

  return (
    <div className={`quiz-header ${showHeader ? 'visible' : ''}`}>
      {/* Logo links */}
      <div className="quiz-header-left">
        <img src="./img/logo_bonusclub.webp" alt="BonusClub Logo" />
      </div>

      {/* Countdown mittig (D/H/M/S mit Labels) */}
      <div className="quiz-header-center">
        <span className="quiz-header-text">LIMITED JOBS AVAILABLE</span>
        
        <div className="quiz-header-countdown-wrapper">
          <div className="quiz-header-countdown-segment">
            <span className="quiz-header-countdown-value">{daysLeft}</span>
            <span className="quiz-header-countdown-label">Days</span>
          </div>
          <div className="quiz-header-countdown-segment">
            <span className="quiz-header-countdown-value">{hoursLeft}</span>
            <span className="quiz-header-countdown-label">Hours</span>
          </div>
          <div className="quiz-header-countdown-segment">
            <span className="quiz-header-countdown-value">{minutesLeft}</span>
            <span className="quiz-header-countdown-label">Min</span>
          </div>
          <div className="quiz-header-countdown-segment">
            <span className="quiz-header-countdown-value">{secondsLeft}</span>
            <span className="quiz-header-countdown-label">Sec</span>
          </div>
        </div>
      </div>

      {/* Button rechts */}
      <div className="quiz-header-right">
        <button className="quiz-header-apply-button" onClick={handleApplyClick}>
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default QuizHeader;
