import React, { useRef, useEffect } from 'react';
import './ApplyStartInfo.css';

const ApplyStartInfo3 = ({ 
  mainTitle, 
  items = [] 
}) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-visible');
        }
      },
      {
        threshold: 0.1
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div className="apply-info3-wrapper">
      {/* Haupttitel */}
      <h2 className="apply-info3-mainTitle" ref={titleRef}>
        {mainTitle}
      </h2>

      {/* Container für die einzelnen Punkte */}
      <div className="apply-info3-items">
        {items.map((item, index) => (
          <div className="apply-info3-item" key={index}>
            {/* Icon oben */}
            <div className="apply-info3-icon-wrapper">
              <img 
                src={item.image} 
                alt="Icon" 
                className="apply-info3-icon" 
              />
            </div>

            {/* Titel */}
            <h3 className="apply-info3-item-title">
              {item.title}
            </h3>

            {/* Beschreibungstext */}
            <p className="apply-info3-item-text">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyStartInfo3;
