import React from 'react';

const DownloadReport = ({ entry, questions }) => {
  const generateReport = () => {
    const steps = [
      { id: 'step1', question: 'Do you have access to a computer with internet connection?' },
      { id: 'step2', question: 'Can you make calls and send messages to potential clients?' },
      { id: 'step3', question: 'Do you have experience in customer communication?' },
      { id: 'step4', question: 'How old are you?' },
      { id: 'step5', question: 'How many hours do you want to work per week?' },
      { id: 'step6', question: 'Where are you currently located?' },
      { id: 'step7', question: 'Are you willing to reach out to new potential clients?' },
      { id: 'step8', question: 'How would you describe your work ethic?' },
      { id: 'step9', question: 'Describe your current situation?' },
      { id: 'step10', question: 'Have you ever done sales work before?' },
      { id: 'step11', question: 'Which tools have you used before?' },
      { id: 'step12', question: 'When would you want to start?' }
    ];

    let reportContent = `BEWERBER REPORT\n`;
    reportContent += `Erstellt am: ${new Date().toLocaleDateString('de-DE')}\n\n`;
    reportContent += `PERSÖNLICHE INFORMATIONEN\n`;
    reportContent += `Name: ${entry.step13?.name || 'Nicht angegeben'}\n`;
    reportContent += `Email: ${entry.step13?.email || 'Nicht angegeben'}\n`;
    reportContent += `Bewerbung eingereicht: ${new Date(entry.timestamp).toLocaleDateString('de-DE')}\n`;
    reportContent += `Gesamtpunktzahl: ${entry.totalScore || 0}\n\n`;
    
    reportContent += `FRAGEBOGEN ANTWORTEN\n\n`;

    steps.forEach(step => {
      reportContent += `Frage: ${step.question}\n`;
      const answer = entry[step.id];
      
      if (Array.isArray(answer)) {
        reportContent += `Antwort: ${answer.join(', ')}\n`;
      } else if (typeof answer === 'boolean') {
        reportContent += `Antwort: ${answer ? 'Ja' : 'Nein'}\n`;
      } else {
        reportContent += `Antwort: ${answer || 'Keine Antwort'}\n`;
      }
      reportContent += `\n`;
    });

    const blob = new Blob([reportContent], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `bewerber-report-${entry.step13?.name || 'unbekannt'}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <button 
      onClick={generateReport}
      className="quiz-dashboard-download-btn"
    >
      Report
    </button>
  );
};

export default DownloadReport; 