import React, { useRef, useEffect } from 'react';
import './ApplyStartInfo.css';

const ApplyStartInfo2 = ({
  mainTitle,
  mainSubtitle,
  leftTitle,
  leftImage,
  leftPoints = [],
  rightTitle,
  rightImage,
  rightPoints = []
}) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-visible');
        }
      },
      {
        threshold: 0.1
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div className="apply-info2-wrapper">
      {/* Oberer Titelbereich */}
      <h2 className="apply-info2-mainTitle" ref={titleRef}>{mainTitle}</h2>
      <p className="apply-info2-subtitle">{mainSubtitle}</p>

      {/* Mittlerer Container: Links - Pfeil - Rechts */}
      <div className="apply-info2-row">
        {/* Linke Spalte */}
        <div className="apply-info2-col apply-info2-col-left">
          {leftImage && (
            <img
              src={leftImage}
              alt="Left visual"
              className="apply-info2-image"
            />
          )}
          <h3 className="apply-info2-col-title">{leftTitle}</h3>
          
          <ul className="apply-info2-list">
            {leftPoints.map((point, index) => (
              <li key={index}>
                <img
                  src="./img/circle-x.svg"
                  alt="X icon"
                  className="apply-info2-bullet-icon"
                />
                {point}
              </li>
            ))}
          </ul>
        </div>

        <div className="apply-info2-col apply-info2-col-right">
          {rightImage && (
            <img
              src={rightImage}
              alt="Right visual"
              className="apply-info2-image"
            />
          )}
          <h3 className="apply-info2-col-title">{rightTitle}</h3>
          
          <ul className="apply-info2-list">
            {rightPoints.map((point, index) => (
              <li key={index}>
                <img
                  src="./img/circle-check.svg"
                  alt="Check icon"
                  className="apply-info2-bullet-icon"
                />
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ApplyStartInfo2;
