// src/Landingpage/Countdown.js
import React, { useEffect, useState, useContext } from 'react';
import { database, ref, get } from '../firebaseConfig';
import { AuthContext } from '../App';
import './Countdown.css';

const Countdown = () => {
  const { shouldUseFirebase } = useContext(AuthContext);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isValidDate, setIsValidDate] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Nur versuchen, Daten zu laden, wenn die Authentifizierung abgeschlossen ist
    if (!shouldUseFirebase) {
      // Zeige einen statischen Countdown, um die UI zu füllen
      setCountdown({ days: 1, hours: 23, minutes: 45, seconds: 0 });
      setIsValidDate(true);
      return;
    }

    const fetchEndDate = async () => {
      try {
        const endDateRef = ref(database, 'SpecialOffer/endDate');
        const snapshot = await get(endDateRef);
        if (snapshot.exists()) {
          const endDate = new Date(snapshot.val());
          if (endDate > new Date()) {
            startCountdown(endDate);
            setIsValidDate(true);
          }
        } else {
          console.log('Kein Enddatum in der Datenbank gefunden. Verwende Standard-Countdown.');
          // Verwende Fallback-Datum (7 Tage ab jetzt)
          const fallbackDate = new Date();
          fallbackDate.setDate(fallbackDate.getDate() + 7);
          startCountdown(fallbackDate);
          setIsValidDate(true);
        }
      } catch (error) {
        console.error('Fehler beim Laden des Enddatums:', error);
        setHasError(true);
        // Verwende Fallback-Datum (7 Tage ab jetzt)
        const fallbackDate = new Date();
        fallbackDate.setDate(fallbackDate.getDate() + 7);
        startCountdown(fallbackDate);
        setIsValidDate(true);
      }
    };

    const startCountdown = (endDate) => {
      const updateCountdown = () => {
        const now = new Date();
        const distance = endDate - now;

        if (distance > 0) {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setCountdown({ days, hours, minutes, seconds });
        } else {
          setIsValidDate(false);
        }
      };

      updateCountdown();
      const interval = setInterval(updateCountdown, 1000);

      return () => clearInterval(interval);
    };

    fetchEndDate();
  }, [shouldUseFirebase]);

  if (!isValidDate) {
    return null;
  }

  return (
    <div className="countdown-container">
      <div className="countdown">
        <div className="time-box">{countdown.days}<span className="label">days</span></div>
        <div className="time-box">{countdown.hours}<span className="label">hours</span></div>
        <div className="time-box">{countdown.minutes}<span className="label">minutes</span></div>
        <div className="time-box">{countdown.seconds}<span className="label">seconds</span></div>
      </div>
      <div className="limited-offer">
        <img src="./img/bell.webp" alt="Bell icon" />
        <a target="_blank" style={{textDecoration: 'none', color: 'black'}} href="https://bonusclub.io/book-call-1">FREE Strategy Call</a>
        <a className="free-strategy-button" href="https://bonusclub.io/book-call-1" target="_blank">
          <img src="./img/down.svg" alt="" />
        </a>
      </div>
    </div>
  );
};

export default Countdown;
