import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set, push, onValue } from 'firebase/database';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_fLPU7h_WbxUGw1W4tku4ye4ooM8uE_s",
  authDomain: "bc-landingpage.firebaseapp.com",
  databaseURL: "https://bc-landingpage-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bc-landingpage",
  storageBucket: "bc-landingpage.appspot.com",
  messagingSenderId: "92095161347",
  appId: "1:92095161347:web:4561a99f7d547431ca9a36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {
  app,
  database,
  ref,
  get,
  onValue,
  set,
  push,
  auth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
};
