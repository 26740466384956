import React, { useState, useEffect } from 'react';
import './FreelanceSteps.css';

const ButtonSelectStep = ({
  question,
  subText,
  buttons,
  multiSelect = false,
  onAnswer,
}) => {
  const [selected, setSelected] = useState(multiSelect ? [] : null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  // Reset selected state when question changes
  useEffect(() => {
    setSelected(multiSelect ? [] : null);
    setIsNextDisabled(true);
  }, [question]);

  // Update button state when selection changes
  useEffect(() => {
    setIsNextDisabled(multiSelect ? selected.length === 0 : selected === null);
  }, [selected, multiSelect]);

  const handleButtonClick = (value) => {
    if (multiSelect) {
      setSelected((prev) =>
        prev.includes(value) ? prev.filter((val) => val !== value) : [...prev, value]
      );
    } else {
      setSelected(value);
    }
  };

  const handleNext = () => {
    if (!isNextDisabled) {
      onAnswer(selected);
    }
  };

  return (
    <div className="quiz-step-container">
      <h2>{question}</h2>
      {subText && <p>{subText}</p>}

      <div className="quiz-button-selector">
        {buttons.map((btn) => (
          <button
            key={btn.value}
            className={`quiz-select-button ${selected === btn.value || (multiSelect && selected.includes(btn.value)) ? 'selected' : ''}`}
            onClick={() => handleButtonClick(btn.value)}
          >
            {btn.label}
          </button>
        ))}
      </div>

      <div className="quiz-button-group" style={{ marginTop: '1rem' }}>
        <button
          className="yes-button"
          onClick={handleNext}
          disabled={isNextDisabled}
          style={{
            backgroundColor: isNextDisabled ? '#ccc' : 'var(--blue)',
            cursor: isNextDisabled ? 'not-allowed' : 'pointer',
            color: isNextDisabled ? 'black' : 'white',
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ButtonSelectStep;
